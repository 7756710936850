<template>
  <footer class="footer mt-auto py-3 fc-white bg-black">
    <div class="container">
      <div class="row footer mb-5">
        <div class="col-md-5 text-start">
          <div class="footer-logo-container">
            <img
              src="@/assets/media/images/logo/logo-compact-on-black.png"
              alt="The Jeans Shop GH Logo - Compact - On Black"
              class="footer-logo"
            />
          </div>

          <div class="mt-4 mb-5 fs-5 footer-logo-container">
            <p>
              <i class="bi bi-tiktok"></i> &nbsp;&nbsp;
              <span class="show-mobile-only-inline"> &nbsp;&nbsp;</span>
              <i class="bi bi-instagram"></i> &nbsp;&nbsp;
              <span class="show-mobile-only-inline"> &nbsp;&nbsp;</span>
              <i class="bi bi-pinterest"></i> &nbsp;&nbsp;
              <span class="show-mobile-only-inline"> &nbsp;&nbsp;</span>
              <i class="bi bi-facebook"></i> &nbsp;&nbsp;
              <span class="show-mobile-only-inline"> &nbsp;&nbsp;</span>
              <i class="bi bi-twitter"></i>
            </p>
          </div>
          <div class="mt-2 show-desktop-only">
            <p class="mb-1">© 2024 The Jeans Shop Gh. All Rights Reserved.</p>
          </div>
          <div class="mt-0 show-desktop-only">
            <p style="font-size: 0.85rem">
              Built by
              <a
                class="text-decoration-none fw-bolder"
                href="https://nusoftgroup.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Nusoft Group</a
              >.
            </p>
          </div>
        </div>
        <div class="col-md-7 ps-2">
          <div class="row">
            <div class="col-4 text-start">
              <h6 class="fw-semibold mb-3">MENU</h6>
              <h6><a href="/about">About Us</a></h6>
              <!-- <h6><a href="/loyalty-rewards">Loyalty Rewards</a></h6> -->

              <h6><a href="/contact">Contact Us</a></h6>
            </div>
            <div class="col-4 text-start">
              <h6 class="fw-semibold mb-3">STORE</h6>
              <h6><a href="/size-chart">Size Chart</a></h6>
              <h6><a href="/login">Login / Register</a></h6>
              <!-- <h6><a href="/store/order-online">Order Online</a></h6>
              <h6 class="mb-4">
                <a href="/store/pickup-in-store">Pickup In-Store</a>
              </h6> -->
              <!-- <h6><a href="/frequently-asked-questions">FAQ's</a></h6> -->
            </div>
            <div class="col-4 text-start">
              <h6 class="fw-semibold mb-3">POLICY</h6>
              <h6><a href="/policy/shipping">Shipping Policy</a></h6>
              <h6 class="mb-4">
                <a href="/policy/returns">Return Policy</a>
              </h6>
              <!-- <h6><a href="/policy/terms-of-service">Terms Of Service</a></h6> -->
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-3 text-start">
              <h6 class="fw-bolder">STORE HOURS</h6>
            </div>
            <div class="col-md-9 text-start fw-bold">
              <h6>Sunday : Closed.</h6>
              <h6>Monday to Friday : 8:00 am - 7:30 pm.</h6>
              <h6>Saturday : 8:00 am - 7:30 pm.</h6>
            </div>
          </div>
          <div class="mt-4">
            <div class="mt-2 show-mobile-only">
              <p class="mb-1">© 2024 The Jeans Shop Gh. All Rights Reserved.</p>
            </div>
            <div class="mt-0 show-mobile-only">
              <p style="font-size: 0.85rem">
                Built by
                <a
                  class="text-decoration-none fw-bolder"
                  href="https://nusoftgroup.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Nusoft Group</a
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
};
</script>