<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <page-header
          title="Orders"
          :return_button="false"
          :action_button="true"
          action_button_text="New Order"
          action_button_link="/staff/orders/add"
        ></page-header>
        <div class="row">
          <div class="col-md-12 mb-3 p-2 text-start box-shadow-light">
            <div class="row">
              <div class="col-md-2 p-1">
                <select
                  @change="getRecords"
                  class="form-select w-100"
                  style="border-radius: 0px; height: 30px; font-size: 0.7rem"
                  v-model="filter.type_code"
                >
                  <option value="">Store or Website</option>
                  <option value="STORE">Store</option>
                  <option value="WEBSITE">Website</option>
                </select>
              </div>
              <div class="col-md-2 p-1">
                <select
                  @change="getRecords"
                  class="form-select w-100"
                  style="border-radius: 0px; height: 30px; font-size: 0.7rem"
                  v-model="filter.delivery_method_code"
                >
                  <option value="">Delivery or Pick Up</option>
                  <option value="delivery">Delivery</option>
                  <option value="self_pick_up">Self Pick Up</option>
                </select>
              </div>
              <div class="col-md-2 p-1">
                <select
                  @change="getRecords"
                  class="form-select w-100"
                  style="border-radius: 0px; height: 30px; font-size: 0.7rem"
                  v-model="filter.user_id"
                >
                  <option value="">Customer / Teller</option>
                  <option value="guest">Guest</option>
                  <option v-for="user in users" :key="user.id" :value="user.id">
                    {{ user.name }} - {{ user.email }}
                  </option>
                </select>
              </div>
              <div class="col-md-2 p-1">
                <select
                  @change="getRecords"
                  class="form-select w-100"
                  style="border-radius: 0px; height: 30px; font-size: 0.7rem"
                  v-model="filter.status_code"
                >
                  <option value="">Status</option>
                  <option value="UNPAID">Unpaid</option>
                  <option value="PROCESSING">Processing</option>
                  <option value="READY_TO_SHIP">Ready to Ship</option>
                  <option value="COMPLETED">Completed</option>
                </select>
              </div>

              <div class="col-md-2 p-1">
                <input
                  style="font-size: 0.7rem"
                  v-model="filter.start_date"
                  type="date"
                  class="form-control text-center fw-bolder"
                  placeholder="Start Date"
                  @change="getRecords"
                />
              </div>
              <div class="col-md-2 p-1">
                <input
                  style="font-size: 0.7rem"
                  v-model="filter.end_date"
                  type="date"
                  class="form-control text-center fw-bolder"
                  placeholder="End Date"
                  @change="getRecords"
                />
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div v-if="!loading.page && orders.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Type</th>
                    <th scope="col">Customer / Teller</th>
                    <th scope="col">Delivery Method</th>
                    <th scope="col">Total</th>
                    <th scope="col">Date</th>
                    <th scope="col">Status</th>
                    <th scope="col" class="text-end">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="order in orders" :key="order.id">
                    <th scope="row">{{ order.id }}</th>
                    <td>{{ order.type_code }}</td>
                    <td class="fw-bolder">
                      <span v-if="order.customer">{{
                        order.customer.email
                      }}</span>
                      <span v-else-if="order.teller">{{
                        order.teller.email
                      }}</span>
                      <span v-else class="fw-light">Guest</span>
                    </td>
                    <td>{{ order.delivery_method.name }}</td>
                    <td>
                      <span class="currency_code">GH₵</span>
                      <b> {{ order.total }}</b>
                    </td>
                    <td>
                      {{
                        moment(order.created_at).format("MMMM Do YYYY, h:mm a")
                      }}
                    </td>
                    <td>{{ order.status.name }}</td>
                    <td class="text-end">
                      <a :href="`/staff/orders/${order.id}/view`" target="_blank">
                        <i class="bi-pencil-square fs-5 text-primary me-2"></i>
                      </a>
                      <!-- <i class="bi-trash fs-5 text-danger"></i> -->
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav>
                <ul class="pagination justify-content-center mt-5">
                  <li v-if="pagination.currentPage > 1" class="page-item">
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Previous"
                      @click.prevent="changePage(pagination.currentPage - 1)"
                    >
                      <span aria-hidden="true">«</span>
                    </a>
                  </li>
                  <li
                    class="page-item"
                    v-for="page in pagination.totalPages"
                    :key="page"
                    :class="{ active: page == pagination.currentPage }"
                  >
                    <a
                      v-if="
                        page < 3 ||
                        page > pagination.totalPages - 3 ||
                        (page < pagination.currentPage + 3 &&
                          page > pagination.currentPage - 3)
                      "
                      class="page-link"
                      @click.prevent="changePage(page)"
                      >{{ page }}</a
                    >
                  </li>
                  <li
                    v-if="pagination.currentPage < pagination.totalPages"
                    class="page-item"
                  >
                    <a
                      class="page-link"
                      aria-label="Next"
                      @click.prevent="changePage(pagination.currentPage + 1)"
                    >
                      <span aria-hidden="true">»</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <center-message v-else-if="orders.length == 0"></center-message>

            <loader v-if="loading.page"> </loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import Loader from "@/components/Loader.vue";
import OrderService from "@/services/staff/OrderService";
import CenterMessage from "@/components/CenterMessage.vue";

export default {
  name: "ListOrdersView",
  components: {
    PageHeader,
    Loader,
    CenterMessage,
  },

  data() {
    return {
      loading: {
        page: false,
      },
      orders: [],
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 0,
        totalPages: 0,
      },
      users: [],
      filter: {
        type_code: "",
        delivery_method_code: "",
        user_id: "",
        status_code: "",
        start_date: "",
        end_date: "",
      },
    };
  },

  async mounted() {
    await this.getRecords();
    OrderService.getOrderUsers().then((response) => {
      this.users = response.data.users;
    });
    this.loading.page = false;
  },

  methods: {
    async getRecords(search) {
      this.loading.page = true;
      if (search === true) {
        this.pagination.currentPage = 1;
      }

      let response = await OrderService.getOrders(
        this.pagination.currentPage,
        this.pagination.perPage,
        this.filter.type_code,
        this.filter.delivery_method_code,
        this.filter.user_id,
        this.filter.status_code,
        this.filter.start_date,
        this.filter.end_date
      );

      this.orders = response.data.orders.data;
      this.pagination.totalRows = response.data.orders.total;
      this.loading.page = false;
      this.pagination.totalPages = response.data.orders.last_page;
    },

    changePage(page) {
      if (this.pagination.currentPage != page) {
        this.pagination.currentPage = page;
        this.getRecords();
      }
    },
  },
};
</script>
