<template>
  <div>
    <div class="row justify-content-center mt-5 m-mt-0">
      <loader v-if="loading.page"></loader>
      <div v-else class="col-md-8 mp-0">
        <div class="row">
          <div class="col-md-6 mp-0">
            <div
              id="productImageCarousel"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <div
                  :class="'carousel-item' + (index == 0 ? ' active' : '')"
                  v-for="(image, index) in product.images"
                  :key="image.id"
                >
                  <img
                    :src="image.file.storage_link"
                    class="d-block w-100"
                    alt=""
                  />
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#productImageCarousel"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#productImageCarousel"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div class="col-md-6 p-4">
            <div v-if="success" class="alert alert-success" role="alert">
              {{ success }}
            </div>
            <div
              v-if="errors.length > 0"
              class="alert alert-danger"
              role="alert"
            >
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </div>
            <a
              v-if="product.next"
              :href="`/shop/product/${product.next.id}/${product.next.slug}`"
              class="show-desktop-only"
            >
              <button
                type="button"
                class="btn btn-sm pe-3 mt-1 mb-4 box-shadow-light"
              >
                <i class="bi bi-chevron-right"></i> Next Item
              </button>
            </a>

            <div class="playfair-display" style="font-size: 1.8rem">
              {{ product.name }}
            </div>
            <div style="font-size: 1.2rem; font-weight: 600">
              <div>
                <span class="currency_code">GH₵ </span
                >{{ product.retail_price }}
              </div>
            </div>
            <div class="mt-3" v-if="product.features.length > 0">
              <ul>
                <li v-for="feature in product.features" :key="feature">
                  {{ feature }}
                </li>
              </ul>
            </div>
            <div class="mt-3">
              <div class="spread-cols-to-ends">
                <div style="font-size: 0.8rem; font-weight: bold">Sizes:</div>
                <div
                  style="font-size: 0.9rem; font-weight: bold"
                  class="show-desktop-only"
                ></div>
              </div>
              <div>
                <span
                  v-for="size in product.sizes"
                  :key="size.id"
                  style="margin-right: 5px"
                >
                  <input
                    v-model="variation.size"
                    :value="size.code"
                    type="radio"
                    class="btn-check"
                    :id="`size-${size.code}`"
                    autocomplete="off"
                  />
                  <label
                    class="btn mt-1 btn-outline-dark btn-sm fw-bold"
                    :for="`size-${size.code}`"
                    >{{ size.name }}</label
                  >
                </span>
              </div>
              <div class="mt-2">
                <a href="/size-chart" style="color: #000; font-size: 0.8rem">
                  Compare on Size Chart
                </a>
              </div>
            </div>
            <div
              class="mt-4"
              v-if="product.colours != null && product.colours.length > 0"
            >
              <div>
                <div style="font-size: 0.8rem; font-weight: bold">Colours:</div>
              </div>
              <div>
                <div
                  v-for="colour in product.colours"
                  :key="colour.code"
                  class="form-check form-check-inline mb-1 mt-1"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="variation.colour"
                    :value="colour.code"
                    :id="`colourCheck${colour.code}`"
                  />
                  <label
                    class="form-check-label"
                    :for="`colourCheck${colour.code}`"
                  >
                    <div
                      class="box-shadow-light mt-1"
                      :style="`width: 30px; height: 18px; background-color: #${colour.code};`"
                    ></div>
                  </label>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div>
                <div style="font-size: 0.8rem; font-weight: bold">
                  Quantity:
                </div>
                <div class="input-group mt-1" style="width: 40%">
                  <button
                    @click="quantity--"
                    style="border: 0.05rem solid #dee2e6 !important"
                    class="btn btn-outline-dark fw-bolder"
                    type="button"
                    id="button-addon1"
                  >
                    -
                  </button>
                  <input
                    v-model="quantity"
                    type="text"
                    class="form-control text-center fw-bolder"
                    placeholder=""
                  />
                  <button
                    @click="quantity++"
                    style="border: 0.05rem solid #dee2e6 !important"
                    class="btn btn-outline-dark fw-bolder"
                    type="button"
                    id="button-addon1"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <button
                :disabled="loading.buyNow"
                type="button"
                class="btn btn-dark"
                style="min-width: 100px"
                @click.prevent="addToCart(true)"
              >
                {{ loading.buyNow ? "Processing Checkout ..." : "Buy Now" }}
              </button>
              &nbsp;
              <button
                :disabled="loading.addToCart"
                type="button"
                class="btn btn-outline-dark"
                @click.prevent="addToCart(false)"
              >
                {{
                  loading.addToCart
                    ? "Adding to Cart ..."
                    : "Add to Shopping Bag"
                }}
              </button>
            </div>
            <div class="mt-3">
              <button
                :disabled="loading.addToCart"
                type="button"
                :class="
                  'btn ' +
                  (!product.favorited ? 'btn-outline-dark' : 'btn-dark')
                "
                @click.prevent="addToWishlist()"
              >
                {{
                  loading.addToCart
                    ? "Adding to Wishlist ..."
                    : !product.favorited
                    ? "Save to Wishlist"
                    : "Remove from Wishlist"
                }}
              </button>
            </div>
            <div class="show-mobile-only mt-5">
              <a
                v-if="product.next"
                :href="`/shop/product/${product.next.id}/${product.next.slug}`"
              >
                <button
                  type="button"
                  class="btn btn-sm pe-3 mt-1 mb-4 box-shadow-light"
                >
                  <i class="bi bi-chevron-right"></i> Next Item
                </button>
              </a>
            </div>
          </div>
        </div>
        <div
          v-if="product.suggestions.length > 0"
          class="row justify-content-center pt-4 pb-4 mt-4"
        >
          <h4 class="playfair-display text-center mb-3">Check these out.</h4>
          <div
            class="col-lg-3 col-6 p-1"
            v-for="product in product.suggestions"
            :key="`product-${product.id}`"
          >
            <product-grid-item
              v-on="$listeners"
              :product="product"
            ></product-grid-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import Loader from "@/components/Loader.vue";
import CartService from "@/services/customer/CartService";
import { formatErrors } from "@/utils/helpers";
import ShopService from "@/services/customer/ShopService";
import WishlistService from "@/services/customer/WishlistService";
import ProductGridItem from "@/components/shop/ProductGridItem.vue";

export default {
  name: "ProductView",
  data() {
    return {
      loading: {
        page: false,
        buyNow: false,
        addToCart: false,
      },
      product: {},
      variation: {
        size: "",
        colour: "",
      },
      quantity: "1",
      success: "",
      errors: [],
    };
  },
  components: {
    Loader,
    ProductGridItem,
  },
  async mounted() {
    await ShopService.getProduct(this.$route.params.id).then((response) => {
      this.product = response.data.product;
      this.variation.size = this.product.sizes[0].code;
      if (this.product.colours.length > 0) {
        this.variation.colour = this.product.colours[0].code;
      }
    });

    this.loading.page = false;
  },
  methods: {
    addToCart(redirectToCheckoutAfter) {
      this.success = "";
      this.errors = [];

      if (redirectToCheckoutAfter) {
        this.loading.buyNow = true;
      } else {
        this.loading.addToCart = true;
      }
      CartService.addToCart({
        product_id: this.product.id,
        size_code: this.variation.size,
        colour_code: this.variation.colour,
        quantity: this.quantity,
      })
        .then((response) => {
          this.success = response.data.message;
          if (redirectToCheckoutAfter) {
            this.$router.push("/checkout");
          }
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.$emit("refreshCartCount");
          if (redirectToCheckoutAfter) {
            this.loading.buyNow = false;
            // Redirect to checkout page
          } else {
            this.loading.addToCart = false;
          }
        });
    },

    async addToWishlist() {
      this.success = "";
      this.errors = [];
      if (this.product.favorited == 1) {
        await WishlistService.deleteWishlistItem(this.product.id).then(
          (response) => {
            this.success = response.data.message;
          }
        );
      } else {
        await WishlistService.addToWishlist({
          product_id: this.product.id,
        }).then((response) => {
          this.success = response.data.message;
        });
      }

      this.product.favorited = !this.product.favorited;
      this.$emit("refreshWishlistCount");
    },
  },
};
</script>