import { APIService } from './../authentication/APIService'

export default {
    getOrders(
        page = 1,
        pageSize = 12,
        type_code = '',
        delivery_method_code = '',
        user_id = '',
        status_code = '',
        start_date = '',
        end_date = '',
    ) {
        // Generate url
        let url = 'api/staff/orders?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize;

        if (type_code) {
            url += '&type_code=' + type_code;
        }

        if (delivery_method_code) {
            url += '&delivery_method_code=' + delivery_method_code;
        }

        if (user_id) {
            url += '&user_id=' + user_id;
        }

        if (status_code) {
            url += '&status_code=' + status_code;
        }

        if (start_date) {
            url += '&start_date=' + start_date;
        }

        if (end_date) {
            url += '&end_date=' + end_date;
        }

        return APIService.get(url);
    },

    getOrderUsers(){
        return APIService.get("api/staff/orders/options/users");
    },

    getOrder(orderId) {
        return APIService.get("api/staff/orders/" + orderId);
    },

    addOrder(payload) {
        return APIService.post("api/staff/orders", payload);
    },

    updateOrder(orderId, payload) {
        return APIService.patch("api/staff/orders/" + orderId, payload);
    },
}