<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <page-header
          title="View Product"
          :return_button="true"
          return_link="/staff/products"
          :action_button="false"
          action_button_text=""
          action_button_link=""
        ></page-header>
        <loader v-if="!product"></loader>
        <div v-else class="row">
          <div class="col-md-12">
            <div v-if="success" class="alert alert-success" role="alert">
              {{ success }}
            </div>
            <div
              v-if="errors.length > 0"
              class="alert alert-danger"
              role="alert"
            >
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </div>
            <ul
              class="nav nav-tabs nav-fill"
              id="viewProductTab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="details-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#details-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="details-tab-pane"
                  aria-selected="true"
                >
                  Details
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="stock-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#stock-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="stock-tab-pane"
                  aria-selected="true"
                >
                  Stock
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="images-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#images-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="images-tab-pane"
                  aria-selected="true"
                >
                  Images
                </button>
              </li>
            </ul>
            <div class="tab-content mt-2" id="viewProductTabContent">
              <div
                class="tab-pane fade show active"
                id="details-tab-pane"
                role="tabpanel"
                aria-labelledby="details-tab"
                tabindex="0"
              >
                <div class="form-group">
                  <label for="name" class="form-label">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    placeholder="Enter category name."
                    v-model="form.name"
                    :disabled="loading.form"
                  />
                </div>

                <div class="form-group">
                  <label for="description" class="form-label"
                    >Description</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="description"
                    placeholder="Enter category description."
                    v-model="form.description"
                    :disabled="loading.form"
                  />
                </div>
                <div class="form-group">
                  <label for="category_id" class="form-label">Category</label>
                  <select
                    v-model="form.category_id"
                    id="category_id"
                    class="form-select form-select-sm"
                    :disabled="loading.form"
                  >
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="price" class="form-label"
                    >Price</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="price"
                    placeholder="Enter category price."
                    v-model="form.retail_price"
                    :disabled="loading.form"
                  />
                </div>
                <button
                  @click.prevent="onSubmit('details')"
                  type="button"
                  class="btn btn-outline-primary mt-1 w-100"
                  :disabled="loading.details"
                >
                  {{
                    loading.details
                      ? "Loading, please wait..."
                      : "Update Product Details"
                  }}
                </button>
              </div>
              <div
                class="tab-pane fade show"
                id="stock-tab-pane"
                role="tabpanel"
                aria-labelledby="stock-tab"
                tabindex="0"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Size</th>
                      <th scope="col">Colour</th>
                      <th scope="col" style="width: 120px">Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="variation in product.variations"
                      :key="variation.id"
                    >
                      <th scope="row">{{ variation.id }}</th>
                      <td>{{ variation.size_code }}</td>
                      <td scope="row">
                        <div
                          v-if="variation.colour_code"
                          class="box-shadow-light"
                          :style="`width: 20px; height: 15px; background-color: #${variation.colour_code};`"
                        ></div>
                        <div v-else class="text-muted">N/a</div>
                      </td>
                      <td>
                        <input
                          style="width: 100px"
                          :value="variation.quantity_in_stock"
                          type="text"
                          class="form-control"
                          :id="'sku_' + variation.id"
                          :ref="'sku_' + variation.id"
                          :disabled="loading.stock"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  @click.prevent="onSubmit('update-stock')"
                  type="button"
                  class="btn btn-outline-primary w-100"
                  :disabled="loading.stock"
                >
                  {{
                    loading.stock
                      ? "Loading, please wait..."
                      : "Update Stock Quantities"
                  }}
                </button>
                <h5 class="playfair-display mt-4 mb-2">Add Variations</h5>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <div>
                        <label for="images" class="form-label mb-1"
                          >Sizes</label
                        >
                        <div>
                          <div
                            v-for="size in sizes"
                            :key="size.code"
                            class="col-md-5 form-check form-check-inline"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="form.sizes"
                              :value="size.code"
                              :id="`sizeCheck${size.code}`"
                            />
                            <label
                              class="form-check-label"
                              :for="`sizeCheck${size.code}`"
                            >
                              <div style="font-size: 0.9rem; font-weight: 600">
                                {{ size.name }}
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <div>
                        <label for="images" class="form-label mb-1"
                          >Colours</label
                        >
                        <div>
                          <div
                            v-for="colour in colours"
                            :key="colour.code"
                            class="form-check form-check-inline mb-1"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              :true-value="[]"
                              v-model="form.colours"
                              :value="colour.code"
                              :id="`colourCheck${colour.code}`"
                            />
                            <label
                              class="form-check-label"
                              :for="`colourCheck${colour.code}`"
                            >
                              <div
                                class="box-shadow-light mt-1"
                                :style="`width: 30px; height: 18px; background-color: #${colour.code};`"
                              ></div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-end">
                  <button
                    @click.prevent="onSubmit('add-stock')"
                    type="button"
                    class="btn btn-outline-primary mt-1 w-100"
                    :disabled="loading.variation"
                  >
                    {{
                      loading.variation
                        ? "Loading, please wait..."
                        : "Add Variations"
                    }}
                  </button>
                </div>
              </div>
              <div
                class="tab-pane fade show"
                id="images-tab-pane"
                role="tabpanel"
                aria-labelledby="images-tab"
                tabindex="0"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Preview</th>
                      <th scope="col" class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="image in product.images" :key="image.id">
                      <th scope="row">{{ image.id }}</th>
                      <td>
                        <img
                          class="lazyload box-shadow-light"
                          :src="image.file.storage_link"
                          alt=""
                          height="50px"
                        />
                      </td>
                      <td class="text-center">
                        <a href="" @click.prevent="deleteImage(image.id)">
                          <i class="bi-trash fs-5 text-danger"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h5 class="playfair-display mt-4 mb-2">Add Images</h5>
                <div class="form-group">
                  <div>
                    <input
                      class="form-control form-control-sm"
                      id="images"
                      type="file"
                      ref="images"
                      @change="onImageChange"
                      multiple
                    />
                  </div>
                </div>
                <button
                  @click.prevent="onSubmit('images')"
                  type="button"
                  class="btn btn-outline-primary w-100"
                  :disabled="loading.images"
                >
                  {{
                    loading.images
                      ? "Loading, please wait..."
                      : "Add Product Images"
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import CategoryService from "@/services/staff/CategoryService";
import ColourService from "@/services/staff/ColourService";
import ProductService from "@/services/staff/ProductService";
import SizeService from "@/services/staff/SizeService";
import { formatErrors } from "@/utils/helpers";
import Loader from "@/components/Loader.vue";

export default {
  name: "AddProductView",
  components: {
    PageHeader,
    Loader,
  },
  data() {
    return {
      loading: {
        stock: false,
        details: false,
        images: false,
        variation: false
      },
      form: {
        name: "",
        description: "",
        category_id: "",
        retail_price: "",
        images: "",
        sizes: [],
        colours: [],
      },
      product: null,
      success: "",
      errors: [],
      categories: [{ id: "", name: "Select Category" }],
      sizes: [],
      colours: [],
    };
  },
  mounted() {
    CategoryService.getCategories(1, 100).then((response) => {
      this.categories = [
        this.categories[0],
        ...response.data.categories.data.map((item) => {
          return {
            id: `${item.id}`,
            name: `${item.name}`,
          };
        }),
      ];
    });

    ColourService.getColours(1, 100).then((response) => {
      this.colours = [
        ...response.data.colours.data.map((item) => {
          return {
            code: `${item.code}`,
            name: `${item.name}`,
          };
        }),
      ];
    });

    SizeService.getSizes(1, 100).then((response) => {
      this.sizes = [
        ...response.data.sizes.data.map((item) => {
          return {
            code: `${item.code}`,
            name: `${item.name}`,
          };
        }),
      ];
    });

    ProductService.getProduct(this.$route.params.id).then((response) => {
      const product = response.data.product;
      this.product = product;
      this.form.name = product.name;
      this.form.description = product.description;
      this.form.category_id = product.category_id;
      this.form.retail_price = product.retail_price;
    });
  },
  methods: {
    onSubmit(type) {
      // Clear success and error message.
      this.success = "";
      this.errors = [];

      if (type == "add-stock") {
        this.loading.variation = true
        ProductService.addProductVariations(this.$route.params.id, {
          product_id: this.$route.params.id,
          sizes: this.form.sizes,
          colours: this.form.colours,
        })
          .then((response) => {
            this.success = response.data.message;
            this.product = response.data.product;
          })
          .catch((error) => {
            let scope = this;
            scope.errors = formatErrors(error);
          })
          .finally(() => {
            this.loading.variation = false;
          });
      } else if (type == "update-stock") {
        this.loading.stock = true;
        let stockData = {};
        this.product.variations.forEach((variation) => {
          stockData["sku_" + variation.id] = document.getElementById(
            "sku_" + variation.id
          ).value;
        });

        ProductService.updateProductVariations(this.$route.params.id, stockData)
          .then((response) => {
            this.success = response.data.message;
          })
          .catch((error) => {
            let scope = this;
            scope.errors = formatErrors(error);
          })
          .finally(() => {
            this.loading.stock = false;
          });
      } else if (type == "images") {
        this.loading.images = true;
        const formData = new FormData();

        if (this.form.images != "") {
          for (let i = 0; i < this.form.images.length; i++) {
            formData.append("images[" + i + "]", this.form.images[i]);
          }
        }

        ProductService.addProductImages(this.$route.params.id, formData)
          .then((response) => {
            this.success = response.data.message;
            this.product = response.data.product;
            this.$refs.images.value = null;
          })
          .catch((error) => {
            let scope = this;
            scope.errors = formatErrors(error);
          })
          .finally(() => {
            this.loading.images = false;
          });
      } else if (type == "details") {
        ProductService.updateProduct(this.$route.params.id, {
          name: this.form.name,
          category_id: this.form.category_id,
          description: this.form.description,
          retail_price: this.form.retail_price,
        })
          .then((response) => {
            this.success = response.data.message;
          })
          .catch((error) => {
            let scope = this;
            scope.errors = formatErrors(error);
          })
          .finally(() => {
            this.loading.images = false;
          });
      }
    },

    deleteImage(imageId) {
      ProductService.deleteProductImage(this.$route.params.id, imageId)
        .then((response) => {
          this.success = response.data.message;
          this.product = response.data.product;
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    onImageChange(e) {
      this.form.images = e.target.files || e.dataTransfer.files;
    },
  },
};
</script>
