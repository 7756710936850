<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-9">
        <page-header
          title="Store Order"
          :return_button="true"
          :action_button="true"
          action_button_text="Reset"
          action_button_link="/staff/orders/add"
        ></page-header>
        <div class="row">
          <div class="col-md-12">
            <div class="row justify-content-center">
              <div v-if="!success" class="col-md-7 p-2">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    placeholder="Enter Product to Search."
                    v-model="name"
                  />
                </div>
                <loader v-if="loading.products"></loader>
                <div v-else class="row">
                  <div class="col-md-12 m-2">
                    <div
                      v-if="errors.product.length > 0"
                      class="alert alert-danger"
                      role="alert"
                    >
                      <ul>
                        <li v-for="error in errors.product" :key="error">
                          {{ error }}
                        </li>
                      </ul>
                    </div>
                    <div class="row">
                      <div class="col-md-12 mt-2">
                        <div
                          class="row mb-2"
                          v-for="(product, index) in products"
                          :key="product.id"
                        >
                          <div class="col-md-1">
                            <img
                              v-if="product.images.length > 0"
                              class="box-shadow-light w-100"
                              :src="product.images[0].file.storage_link"
                              alt=""
                            />
                            <p
                              v-else
                              class="text-muted"
                              style="font-size: 0.7rem"
                            >
                              No Image
                            </p>
                          </div>
                          <div class="col-md-11">
                            <div class="spread-cols-to-ends text-end">
                              <div
                                class="p-2 pt-0 pb-0"
                                style="font-size: 0.9rem; font-weight: 700"
                              >
                                <div class="text-start">
                                  {{ product.name }}
                                </div>

                                <div class="text-start">
                                  <span
                                    v-for="size in product.sizes"
                                    :key="`${product.id}-size-${size.code}`"
                                    style="margin-right: 5px"
                                  >
                                    <input
                                      v-model="products[index].selectedSize"
                                      :value="size"
                                      type="radio"
                                      class="btn-check"
                                      :id="`${product.id}-size-${size.code}`"
                                      autocomplete="off"
                                    />
                                    <label
                                      style="
                                        font-size: 0.7rem;
                                        padding: 0px 2px 0px 2px;
                                        border-width: 0.01px;
                                      "
                                      class="btn btn-outline-dark btn-sm fw-bold pr-1 pl-1"
                                      :for="`${product.id}-size-${size.code}`"
                                      >{{ size.name }}</label
                                    >
                                  </span>
                                </div>
                                <div
                                  v-if="product.colours"
                                  class="text-start"
                                  style="font-size: 0.6rem"
                                >
                                  <div
                                    v-for="colour in product.colours"
                                    :key="`${product.id}-colour-${colour.code}`"
                                    class="form-check form-check-inline mb-1 mt-1"
                                    style="margin-right: 6px; min-height: 1rem"
                                  >
                                    <label
                                      class="form-check-label"
                                      :for="`${product.id}-colour-${colour.code}`"
                                    >
                                      <div
                                        class="box-shadow-light mt-1"
                                        :style="`width: 20px; height: 10px; background-color: #${colour.code};`"
                                      ></div>
                                    </label>
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      v-model="products[index].selectedColour"
                                      :value="colour"
                                      :id="`${product.id}-colour-${colour.code}`"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div
                                  style="font-size: 0.9rem; font-weight: 600"
                                >
                                  {{ product.retail_price }}
                                </div>
                                <a
                                  href=""
                                  @click.prevent="addToCart(products[index])"
                                >
                                  <i
                                    class="bi-bag-plus fs-5 text-success me-2"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <hr
                            v-if="index != products.length - 1"
                            class="mt-2 mb-0"
                          />
                        </div>
                      </div>
                    </div>
                    <nav>
                      <ul class="pagination justify-content-center mt-2">
                        <li v-if="pagination.currentPage > 1" class="page-item">
                          <a
                            class="page-link"
                            href="#"
                            aria-label="Previous"
                            @click.prevent="
                              changePage(pagination.currentPage - 1)
                            "
                          >
                            <span aria-hidden="true">«</span>
                          </a>
                        </li>
                        <li
                          class="page-item"
                          v-for="page in pagination.totalPages"
                          :key="page"
                          :class="{ active: page == pagination.currentPage }"
                        >
                          <a
                            v-if="
                              page < 3 ||
                              page > pagination.totalPages - 3 ||
                              (page < pagination.currentPage + 3 &&
                                page > pagination.currentPage - 3)
                            "
                            class="page-link"
                            @click.prevent="changePage(page)"
                            >{{ page }}</a
                          >
                        </li>
                        <li
                          v-if="pagination.currentPage < pagination.totalPages"
                          class="page-item"
                        >
                          <a
                            class="page-link"
                            aria-label="Next"
                            @click.prevent="
                              changePage(pagination.currentPage + 1)
                            "
                          >
                            <span aria-hidden="true">»</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div class="col-md-5 p-2">
                <div class="row">
                  <div class="col-md-12 m-2 box-shadow-light p-4">
                    <div
                      v-if="success"
                      class="alert alert-success"
                      role="alert"
                    >
                      {{ success }}
                    </div>
                    <div
                      v-if="errors.checkout.length > 0"
                      class="alert alert-danger"
                      role="alert"
                    >
                      <ul>
                        <li v-for="error in errors.checkout" :key="error">
                          {{ error }}
                        </li>
                      </ul>
                    </div>
                    <div class="pt-2" style="min-height: 400px">
                      <div class="center-cart-empty" v-if="cart.length == 0">
                        <p>Add Products to Begin Checkout.</p>
                      </div>
                      <div v-else>
                        <div v-for="(item, index) in cart" :key="index">
                          <div class="spread-cols-to-ends mt-2">
                            <div class="text-start">
                              <div style="font-size: 0.9rem; font-weight: bold">
                                {{ item.product.name }}
                              </div>
                              <div
                                style="font-size: 0.7rem; font-weight: normal"
                              >
                                <span>{{ item.size.name }}</span>
                                <span v-if="item.colour"
                                  >, {{ item.colour.name }}</span
                                >
                                <span class="fw-bolder">
                                  x {{ item.quantity }}</span
                                >
                              </div>
                            </div>
                            <div style="font-size: 0.9rem; font-weight: bold">
                              {{ item.product.retail_price * item.quantity }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="spread-cols-to-ends">
                      <div></div>
                      <div
                        class="text-end"
                        style="font-weight: bolder; font-size: xx-large"
                      >
                        <div>
                          <span class="currency_code" style="font-size: 0.8rem"
                            >GH₵
                          </span>
                          {{ formatAmount(total) }}
                        </div>
                      </div>
                    </div>
                    <div class="form-group" v-if="cart.length > 0 && !success">
                      <select
                        v-model="account"
                        id="account"
                        class="form-select form-select-sm"
                        :disabled="loading.checkout"
                      >
                        <option value="CASH">Cash</option>
                        <option value="MTN_MOBILE_MONEY">
                          MTN Mobile Money Direct
                        </option>
                        <option value="BANK">POS / Bank</option>
                      </select>
                    </div>
                    <button
                      v-if="cart.length > 0 && !success"
                      type="submit"
                      class="btn btn-md btn-outline-success mt-0 btn-dark w-100"
                      @click.prevent="submitOrder"
                    >
                      <span v-if="!loading.checkout">
                        <b style="font-weight: bolder">
                          <i class="bi bi-credit-card mr-4"></i>
                          &nbsp;Checkout</b
                        >
                      </span>
                      <span v-else>
                        <b style="font-weight: bolder">
                          <i class="bi bi-credit-card mr-4"></i> &nbsp;Loading.
                          Please wait ...
                        </b>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import ProductService from "@/services/staff/ProductService";
import Loader from "@/components/Loader.vue";
import OrderService from "@/services/staff/OrderService";
import { formatErrors } from "@/utils/helpers";
import jsPDF from "jspdf";

export default {
  name: "AddOrderView",
  components: {
    PageHeader,
    Loader,
  },
  async mounted() {
    await this.getRecords();
    this.loading.products = false;
  },
  data() {
    return {
      loading: {
        products: true,
        checkout: false,
      },
      name: "",
      products: [],
      total: 0,
      cart: [],
      account: "CASH",
      order: {},
      pagination: {
        currentPage: 1,
        perPage: 7,
        totalRows: 0,
        totalPages: 0,
      },
      success: "",
      errors: {
        product: [],
        checkout: [],
      },
    };
  },
  methods: {
    async getRecords(search) {
      this.loading.products = true;
      if (search === true) {
        this.pagination.currentPage = 1;
      }

      let response = await ProductService.getProducts(
        this.pagination.currentPage,
        this.pagination.perPage,
        this.name
      );

      this.products = response.data.products.data;
      this.pagination.totalRows = response.data.products.total;
      this.loading.products = false;
      this.pagination.totalPages = response.data.products.last_page;

      this.loading.products = false;
    },

    changePage(page) {
      if (this.pagination.currentPage != page) {
        this.pagination.currentPage = page;
        this.getRecords();
      }
    },

    addToCart(product) {
      // Validate product
      this.errors.product = [];

      if (product.selectedSize == null) {
        this.errors.product.push("Select a size for " + product.name);
      }

      if (product.colours.length > 0 && product.selectedColour == null) {
        this.errors.product.push("Select a colour for " + product.name);
      }

      if (this.errors.product.length > 0) {
        return;
      }

      var cartIndex = null;

      for (let index = 0; index < this.cart.length; index++) {
        if (
          this.cart[index].product.id == product.id &&
          this.cart[index].size.id == product.selectedSize.id
        ) {
          if (product.selectedColour != null) {
            if (
              this.cart[index].colour != null &&
              this.cart[index].colour.id == product.selectedColour.id
            ) {
              cartIndex = index;
              break;
            }
          } else {
            cartIndex = index;
            break;
          }
        }
      }

      if (cartIndex != null) {
        // Add to quantity.
        this.cart[cartIndex].quantity++;
      } else {
        this.cart.push({
          product: product,
          size: product.selectedSize,
          colour: product.selectedColour,
          quantity: 1,
        });
      }
      var audio = new Audio("/sounds/beep.wav"); // path to file
      audio.volume = 0.05;
      audio.play();

      this.calculateTotal();
    },

    calculateTotal() {
      this.total = 0;
      for (let index = 0; index < this.cart.length; index++) {
        this.total +=
          this.cart[index].product.retail_price * this.cart[index].quantity;
      }
    },

    submitOrder() {
      this.loading.checkout = true;
      // Prepare cart items.
      var items = [];
      for (let index = 0; index < this.cart.length; index++) {
        items.push({
          product_id: this.cart[index].product.id,
          size_code: this.cart[index].size.code,
          colour_code: this.cart[index].colour
            ? this.cart[index].colour.code
            : "",
          quantity: this.cart[index].quantity,
        });
      }

      OrderService.addOrder({
        account_code: this.account,
        type_code: "STORE",
        delivery_method_code: "self_pick_up",
        subtotal: this.total,
        discount: 0,
        total: this.total,
        items: items,
      })
        .then((response) => {
          this.success = response.data.message;
          this.order = response.data.order;
          this.printReceipt();
        })
        .catch((error) => {
          let scope = this;
          scope.errors.checkout = formatErrors(error);
        })
        .finally(() => {
          this.loading.checkout = false;
        });
    },

    printReceipt() {
      this.docYPointer = 1;
      this.doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: [3.14, 11],
      });

      this.doc.setFontSize(12);
      this.doc.text(
        "THE JEANS SHOP GH",
        this.doc.internal.pageSize.getWidth() / 2,
        0.2,
        { align: "center" }
      );
      this.doc.setFontSize(10);
      this.doc.text(
        "www.thejeansshopgh.com",
        this.doc.internal.pageSize.getWidth() / 2,
        0.4,
        { align: "center" }
      );
      this.doc.text(
        "IG - @thejeansshopgh",
        this.doc.internal.pageSize.getWidth() / 2,
        0.6,
        { align: "center" }
      );
      this.doc.text(
        "054-566-1775 / 055-516-2374",
        this.doc.internal.pageSize.getWidth() / 2,
        0.8,
        { align: "center" }
      );
      this.doc.text(
        "Ashale Botwe - Lakeside",
        this.doc.internal.pageSize.getWidth() / 2,
        1,
        { align: "center" }
      );
      this.doc.text(
        "-----------------------------------------------",
        this.doc.internal.pageSize.getWidth() / 2,
        1.15,
        { align: "center" }
      );
      for (let i = 0; i < this.cart.length; i++) {
        // Get cart item sku.
        this.docYPointer += 0.5;
        this.doc.text(
          i +
            1 +
            ". " +
            this.cart[i].product.name +
            " - " +
            this.cart[i].size.name +
            (this.cart[i].colour ? ", " + this.cart[i].colour.name : ""),
          0.1,
          this.docYPointer,
          {}
        );
        this.doc.text(
          this.pad(
            "GHS " +
              this.cart[i].product.retail_price * 1 +
              " x " +
              this.cart[i].quantity
          ) +
            "...............  GHS " +
            this.cart[i].product.retail_price * this.cart[i].quantity,
          0.1,
          this.docYPointer + 0.2,
          {}
        );
      }
      this.doc.text(
        this.pad("Sub-Total:") + "..................  GHS " + this.total,
        0.1,
        this.docYPointer + 1,
        {}
      );
      this.doc.text(
        this.pad("Discount:") + "..................  GHS " + 0,
        0.1,
        this.docYPointer + 1.2,
        {}
      );
      this.doc.text(
        this.pad("Total:") + "..................  GHS " + this.total,
        0.1,
        this.docYPointer + 1.4,
        {}
      );
      this.doc.text(
        "Paid By: " + this.account,
        0.1,
        this.docYPointer + 1.7,
        {}
      );
      this.doc.text(
        "Cashier: " + this.order.teller.name,
        0.1,
        this.docYPointer + 1.9,
        {}
      );
      this.doc.text(
        "Order Ref: " + this.order.id,
        0.1,
        this.docYPointer + 2.1,
        {}
      );

      this.doc.text(
        "Thank you for shopping with us!",
        this.doc.internal.pageSize.getWidth() / 2,
        this.docYPointer + 2.6,
        { align: "center" }
      );
      this.doc.setFontSize(8);
      this.doc.text(
        "Developed by Nusoft Group",
        this.doc.internal.pageSize.getWidth() / 2,
        this.docYPointer + 3,
        { align: "center" }
      );
      this.doc.text(
        "hello@nusoftgroup.com",
        this.doc.internal.pageSize.getWidth() / 2,
        this.docYPointer + 3.15,
        { align: "center" }
      );
      this.doc.text(
        ".",
        this.doc.internal.pageSize.getWidth() / 2,
        this.docYPointer + 5,
        { align: "center" }
      );
      this.doc.save(this.order.id + ".pdf");
    },

    formatAmount(number) {
      return new Intl.NumberFormat("en-GH", {}).format(number);
    },

    pad(string) {
      var padding = "  ";
      for (let index = 0; index < 20 - string.length; index++) {
        padding += "..";
      }
      return string + padding;
    },
  },
  watch: {
    name: {
      handler: function () {
        this.getRecords(true);
      },
    },
  },
};
</script>
