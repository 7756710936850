<template>
  <div v-if="local_product != null">
    <a
      :href="`/shop/product/${local_product.id}/${local_product.slug}`"
      style="text-decoration: none; color: #000000"
    >
      <div class="">
        <img v-lazy="local_product.images[0].file.storage_link" width="100%" />
        <i
          :class="
            `bi ` +
            (local_product.favorited == 0 ? `bi-heart` : `bi-heart-fill`)
          "
          @click.prevent="favorite"
          style="
            position: absolute;
            margin-left: -40px;
            margin-top: 10px;
            font-size: 1.6rem;
          "
        ></i>
      </div>
      <div style="font-weight: 400; font-size: 0.9rem" class="mt-1">
        {{ local_product.name }}
      </div>
      <div style="font-weight: 900; font-size: 0.9rem">
        <span class="currency_code">GH₵</span> {{ local_product.retail_price }}
      </div>
    </a>
  </div>
</template>
<script>
import WishlistService from "@/services/customer/WishlistService";

export default {
  name: "ProductGridItem",
  props: ["product"],
  data() {
    return {
      local_product: null,
    };
  },
  mounted() {
    this.local_product = this.product;
  },
  methods: {
    async favorite() {
      if (this.local_product.favorited == 1) {
        await WishlistService.deleteWishlistItem(this.local_product.id);
      } else {
        await WishlistService.addToWishlist({ product_id: this.local_product.id });
      }
      this.local_product.favorited = !this.local_product.favorited;
      this.$emit("refreshWishlistCount");
    },
  },
};
</script>