<template>
  <div>
    <loader v-if="loading.page"></loader>
    <div v-else class="row justify-content-center m-bg-grey">
      <div class="col-md-7 mt-5 m-mt-0 mb-5">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-9">
            <nav
              class="show-desktop-only"
              style="--bs-breadcrumb-divider: '>'"
              aria-label="breadcrumb"
            >
              <ol v-if="success != ''" class="breadcrumb">
                <li class="breadcrumb-item active">Cart</li>
                <li class="breadcrumb-item active" aria-current="page">
                  Information
                </li>
                <li class="breadcrumb-item active" aria-current="page">Pay</li>
                <li class="breadcrumb-item" aria-current="page">
                  Order Complete
                </li>
              </ol>
              <ol v-else class="breadcrumb">
                <li class="breadcrumb-item active">Cart</li>
                <li class="breadcrumb-item" aria-current="page">Information</li>
                <li class="breadcrumb-item active" aria-current="page">Pay</li>
                <li class="breadcrumb-item active" aria-current="page">
                  Order Complete
                </li>
              </ol>
            </nav>
            <loader v-if="loading.order"></loader>
            <div
              v-if="!loading.order && success == ''"
              class="show-desktop-only"
            >
              <div class="row">
                <div class="col-md-12">
                  <div class="fc-white bg-dark p-3">
                    <p class="p-0">
                      Note! Delivery is usually done
                      <b>within 36 working hours</b> after your order is placed.
                      If for any reason we can't deliver within the stated
                      period, you will be informed.
                    </p>
                    <p class="p-0">
                      However, for emergency situations, we strongly advise that
                      you choose the in-store pick up option and send a rider to
                      pick up for you an hour after the order is placed or call
                      us to arrange for delivery.
                    </p>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <b>Discount Code</b>
                <div class="input-group w-100" style="border-radius: 0px">
                  <input
                    v-model="coupon_code"
                    type="text"
                    class="form-control"
                    placeholder="Enter Discount Code E.g. NEWSHOPPER2024"
                    aria-describedby="search-button"
                  />
                  <button
                    class="btn btn-dark"
                    type="button"
                    id="search-button"
                    :disabled="loading.coupon"
                    @click.prevent="validateCoupon"
                  >
                    {{
                      loading.coupon ? "Verifying ... " : "Apply Discount Code"
                    }}
                  </button>
                </div>
              </div>
              <div class="row mt-4">
                <b>Delivery Method</b>
                <div class="col-md-12">
                  <span class="w-100">
                    <input
                      @change="calculateTotals"
                      v-model="deliveryMethod"
                      value="self_pick_up"
                      type="radio"
                      class="btn-check"
                      id="walk-in"
                      autocomplete="off"
                    />
                    <label
                      class="btn mt-1 btn-outline-dark fw-bold w-100 text-start"
                      for="walk-in"
                    >
                      <i class="bi bi-person-walking"></i> Walk In</label
                    >
                  </span>
                  <span class="w-100">
                    <input
                      @change="calculateTotals"
                      v-model="deliveryMethod"
                      value="delivery"
                      type="radio"
                      class="btn-check"
                      id="delivery"
                      autocomplete="off"
                    />
                    <label
                      class="btn btn-outline-dark fw-bold w-100 text-start"
                      for="delivery"
                    >
                      <i class="bi bi-bicycle"></i> Delivery</label
                    >
                  </span>
                </div>
              </div>

              <div
                v-if="deliveryMethod == 'self_pick_up'"
                class="row mt-5 mb-5"
              >
                <b>Pickup Location</b>
                <p class="mt-2">
                  Our Address: <br />
                  <b>The Jeans Shop GH</b> on Maps <br />
                  Lakeside Community 1
                </p>
                <img
                  class="mb-5"
                  src="@/assets/media/images/checkout/ride-hailing-services.png"
                  alt=""
                  style="width: 250px"
                />
              </div>
              <div v-if="deliveryMethod == 'delivery'" class="row mt-5 mb-5">
                <b>Information</b>
                <div class="col-md-12 mt-1">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="first-name"
                          placeholder="First name"
                          v-model="information.first_name"
                          :disabled="loading.form"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="last-name"
                          placeholder="Last name"
                          v-model="information.last_name"
                          :disabled="loading.form"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="phone"
                          placeholder="Phone number"
                          v-model="information.phone"
                          :disabled="loading.form"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="email"
                          placeholder="Email"
                          v-model="information.email"
                          :disabled="loading.form"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <select
                          @change="getRegions"
                          placeholder="Select Country"
                          class="form-select w-100"
                          style="border-radius: 0px; width: 250px"
                          v-model="information.country_code"
                        >
                          <option
                            v-for="country in countries"
                            :key="country.id"
                            :value="country.code"
                          >
                            {{ country.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <select
                          placeholder="Select Region"
                          @change="getZones"
                          class="form-select w-100"
                          style="border-radius: 0px; width: 250px"
                          v-model="information.region_code"
                          required
                        >
                          <option value="">Select Region</option>
                          <option
                            v-for="region in regions"
                            :key="region.id"
                            :value="region.code"
                          >
                            {{ region.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <select
                          placeholder="Select Delivery Zone"
                          class="form-select w-100"
                          style="border-radius: 0px; width: 250px"
                          v-model="information.zone_code"
                          @change="calculateTotals"
                          required
                        >
                          <option value="">Select Delivery Zone</option>
                          <option
                            v-for="zone in zones"
                            :key="zone.id"
                            :value="zone.code"
                          >
                            {{ zone.name + " - " + zone.locations }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <textarea
                          type="text"
                          class="form-control"
                          id="address"
                          placeholder="Address"
                          v-model="information.address"
                          :disabled="loading.form"
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <textarea
                          type="text"
                          class="form-control"
                          id="notes"
                          placeholder="Additional notes"
                          v-model="information.notes"
                          :disabled="loading.form"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="!loading.order && success != ''">
              <div class="row">
                <div class="col-md-12 p-3">
                  <h1 class="playfair-display">Order Received</h1>
                  <p>Thank you. Your order has been received</p>
                  <div
                    class="bg-grey p-4"
                    style="border-radius: 15px; font-size: 0.9rem"
                  >
                    <div>Order number:</div>
                    <div class="fw-bold"># {{ order.id }}</div>
                    <hr />
                    <div>Date:</div>
                    <div class="fw-bold">
                      {{
                        moment(order.updated_at).format("MMMM Do YYYY, h:mm a")
                      }}
                    </div>
                    <hr />
                    <div>Total:</div>
                    <div class="fw-bolder">
                      <span class="currency_code" style="font-size: 0.7rem"
                        >GH₵
                      </span>
                      {{ formatAmount(order.total) }}
                    </div>
                    <hr />
                    <div>Payment Method:</div>
                    <div class="fw-bold">
                      Online - Debit / Credit Card / Mobile Money
                    </div>
                  </div>
                  <div class="mt-4 text-center">
                    <a href="/cart" style="color: black">
                      <b style="font-weight: bolder">
                        Continue Shopping
                        <i class="bi bi-chevron-right"></i>
                      </b>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!loading.order && success == ''"
        class="col-md-5 p-5 pt-2 bg-grey mobile-checkout-product-area"
      >
        <div class="row mt-5 m-mt-0">
          <div v-if="errors.length > 0" class="alert alert-danger" role="alert">
            <ul>
              <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </div>
          <div
            class="col-12 pt-3 cart-items"
            id="cart-items"
            style="border-radius: 10px"
          >
            <div
              class="row p-3 pb-0 pt-0"
              v-for="(cartItem, index) in cart.items"
              :key="cartItem.id"
            >
              <div class="col-2 mb-3" style="padding-left: 0">
                <img
                  :src="
                    cartItem.product_variation.product.images[0].file
                      .storage_link
                  "
                  width="100%"
                  class="lazyload"
                />
                <span
                  class="badge badge-dark fw-bolder"
                  style="
                    background-color: black;
                    position: absolute;
                    margin-left: -10px;
                    margin-top: -10px;
                    font-size: 0.7rem;
                    border-radius: 15px;
                  "
                  >{{ cartItem.quantity }}</span
                >
              </div>
              <div
                class="col-10 p-2 pt-1"
                style="padding-left: 15px !important"
              >
                <div class="row">
                  <div class="col-md-12 p-0 spread-cols-to-ends">
                    <div>
                      <span class="fw-bolder"
                        >{{ cartItem.product_variation.product.name }}
                      </span>
                      <div>
                        <span style="">
                          <span class="fw-normal mt-2" style="font-size: 0.9rem"
                            >{{ cartItem.product_variation.size.name }}
                          </span>
                          <div
                            v-if="cartItem.product_variation.colour_code"
                            class="box-shadow-light"
                            :style="`width: 30px; height: 18px; background-color: #${cartItem.product_variation.colour_code}; margin-top: 5px`"
                          ></div>
                        </span>
                      </div>
                    </div>
                    <div class="">
                      <div class="fw-bold text-end">
                        <span class="currency_code">GH₵ </span
                        >{{
                          cartItem.product_variation.product.retail_price *
                          cartItem.quantity
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr v-if="index != cart.items.length - 1" />
            </div>
          </div>
          <div
            v-if="!loading.order && success == ''"
            class="row mt-3 show-mobile-only p-2"
          >
            <div class="input-group w-100" style="border-radius: 0px">
              <input
                v-model="coupon_code"
                type="text"
                class="form-control"
                placeholder="Enter Discount Code E.g. NEWSHOPPER2024"
                aria-describedby="search-button"
              />
              <button
                class="btn btn-dark"
                type="button"
                id="search-button"
                :disabled="loading.coupon"
                @click.prevent="validateCoupon"
              >
                {{ loading.coupon ? "Verifying ... " : "Apply" }}
              </button>
            </div>
          </div>
          <div class="col-md-12 mt-4 p-2">
            <div
              class="spread-cols-to-ends"
              style="font-weight: bold; font-size: large"
            >
              <div>Subtotal:</div>
              <div>
                <span class="currency_code" style="font-size: 0.8rem"
                  >GH₵
                </span>
                {{ formatAmount(cart.totals.subtotal) }}
              </div>
            </div>
            <div
              class="spread-cols-to-ends mt-1"
              style="font-weight: 500; font-size: large"
            >
              <div style="font-size: 1rem">Delivery Fee:</div>
              <div>
                <span class="currency_code" style="font-size: 0.8rem"
                  >GH₵
                </span>
                {{ formatAmount(cart.totals.shipping) }}
              </div>
            </div>
            <div
              class="spread-cols-to-ends"
              style="font-weight: 500; font-size: large"
            >
              <div style="font-size: 1rem">Discount:</div>
              <div>
                <span class="currency_code" style="font-size: 0.8rem"
                  >GH₵
                </span>
                {{ formatAmount(cart.totals.discount) }}
              </div>
            </div>
            <hr class="mt-1 mb-1" />
            <div
              class="text-end"
              style="font-weight: bolder; font-size: xx-large"
            >
              <div>
                <span class="currency_code" style="font-size: 0.8rem"
                  >GH₵
                </span>
                {{ formatAmount(cart.totals.total) }}
              </div>
            </div>
          </div>
          <div>
            <div
              v-if="!loading.order && success == ''"
              class="show-mobile-only"
            >
              <div class="row">
                <div class="col-md-12 p-0">
                  <div class="fc-white bg-dark p-3">
                    <p class="p-0">
                      Note! Delivery is usually done
                      <b>within 36 working hours</b> after your order is placed.
                      If for any reason we can't deliver within the stated
                      period, you will be informed.
                    </p>
                    <p class="p-0">
                      However, for emergency situations, we strongly advise that
                      you choose the in-store pick up option and send a rider to
                      pick up for you an hour after the order is placed or call
                      us to arrange for delivery.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!loading.order && success == ''"
              class="show-mobile-only p-3"
            >
              <div class="row mt-4">
                <b>Delivery Method</b>
                <div class="col-md-12">
                  <span class="w-100">
                    <input
                      @change="calculateTotals"
                      v-model="deliveryMethod"
                      value="self_pick_up"
                      type="radio"
                      class="btn-check"
                      id="walk-in"
                      autocomplete="off"
                    />
                    <label
                      class="btn mt-1 btn-outline-dark fw-bold w-100 text-start"
                      for="walk-in"
                    >
                      <i class="bi bi-person-walking"></i> Walk In</label
                    >
                  </span>
                  <span class="w-100">
                    <input
                      @change="calculateTotals"
                      v-model="deliveryMethod"
                      value="delivery"
                      type="radio"
                      class="btn-check"
                      id="delivery"
                      autocomplete="off"
                    />
                    <label
                      class="btn btn-outline-dark fw-bold w-100 text-start"
                      for="delivery"
                    >
                      <i class="bi bi-bicycle"></i> Delivery</label
                    >
                  </span>
                </div>
              </div>

              <div v-if="deliveryMethod == 'self_pick_up'" class="row mt-4">
                <b>Pickup Location</b>
                <p class="mt-2">
                  Our Address: <br />
                  <b>The Jeans Shop GH</b> on Maps <br />
                  Lakeside Community 1
                </p>
                <img
                  class="mb-5"
                  src="@/assets/media/images/checkout/ride-hailing-services.png"
                  alt=""
                  style="width: 250px"
                />
              </div>
              <div v-if="deliveryMethod == 'delivery'" class="row mt-5 mb-0">
                <b>Information</b>
                <div class="col-md-12 mt-1">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="first-name"
                          placeholder="First name"
                          v-model="information.first_name"
                          :disabled="loading.form"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="last-name"
                          placeholder="Last name"
                          v-model="information.last_name"
                          :disabled="loading.form"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="phone"
                          placeholder="Phone number"
                          v-model="information.phone"
                          :disabled="loading.form"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="email"
                          placeholder="Email"
                          v-model="information.email"
                          :disabled="loading.form"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <select
                          @change="getRegions"
                          placeholder="Select Country"
                          class="form-select w-100"
                          style="border-radius: 0px; width: 250px"
                          v-model="information.country_code"
                        >
                          <option
                            v-for="country in countries"
                            :key="country.id"
                            :value="country.code"
                          >
                            {{ country.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <select
                          placeholder="Select Region"
                          @change="getZones"
                          class="form-select w-100"
                          style="border-radius: 0px; width: 250px"
                          v-model="information.region_code"
                          required
                        >
                          <option value="">Select Region</option>
                          <option
                            v-for="region in regions"
                            :key="region.id"
                            :value="region.code"
                          >
                            {{ region.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <select
                          placeholder="Select Delivery Zone"
                          class="form-select w-100"
                          style="border-radius: 0px; width: 250px"
                          v-model="information.zone_code"
                          @change="calculateTotals"
                          required
                        >
                          <option value="">Select Delivery Zone</option>
                          <option
                            v-for="zone in zones"
                            :key="zone.id"
                            :value="zone.code"
                          >
                            {{ zone.name + " - " + zone.locations }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <textarea
                          type="text"
                          class="form-control"
                          id="address"
                          placeholder="Address"
                          v-model="information.address"
                          :disabled="loading.form"
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <textarea
                          type="text"
                          class="form-control"
                          id="notes"
                          placeholder="Additional notes"
                          v-model="information.notes"
                          :disabled="loading.form"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="spread-cols-to-ends show-desktop-only"
              v-if="success == ''"
            >
              <a href="/cart">
                <button
                  type="button"
                  class="btn btn-lg mt-3 btn-outline-dark w-70"
                >
                  <b style="font-weight: bolder">
                    <i class="bi bi-chevron-left"></i>
                    Return to Cart</b
                  >
                </button>
              </a>
              <button
                type="submit"
                class="btn btn-lg mt-3 btn-dark w-50"
                @click.prevent="submitOrder"
              >
                <span v-if="!loading.form">
                  <b style="font-weight: bolder">
                    <i class="bi bi-credit-card mr-4"></i>
                    &nbsp;Make Payment</b
                  >
                </span>
                <span v-else>
                  <b style="font-weight: bolder">
                    <i class="bi bi-credit-card mr-4"></i> &nbsp;Loading. Please
                    wait ...
                  </b>
                </span>
              </button>
            </div>
            <div class="show-mobile-only p-0 m-2 mb-5" v-if="success == ''">
              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-lg btn-dark w-100 mb-3"
                  @click.prevent="submitOrder"
                >
                  <span v-if="!loading.form">
                    <b style="font-weight: bolder">
                      <i class="bi bi-credit-card mr-4"></i>
                      &nbsp;Make Payment</b
                    >
                  </span>
                  <span v-else>
                    <b style="font-weight: bolder">
                      <i class="bi bi-credit-card mr-4"></i> &nbsp;Loading.
                      Please wait ...
                    </b>
                  </span>
                </button>
                <a href="/cart" style="color: black">
                  <b style="font-weight: bolder">
                    <i class="bi bi-chevron-left"></i>
                    Return to Cart</b
                  >
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import CheckoutService from "@/services/customer/CheckoutService";
import CartService from "@/services/customer/CartService";
import Loader from "@/components/Loader.vue";
import { formatErrors } from "@/utils/helpers";
export default {
  name: "CheckoutView",
  components: { Loader },
  data() {
    return {
      loading: {
        page: true,
        form: false,
        order: false,
        coupon: false,
      },
      deliveryMethod: "self_pick_up",
      coupon_code: "",
      information: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country_code: "GH",
        region_code: "GH-AA",
        zone_code: "ZONE1",
        address: "",
        notes: "",
      },
      coupon: {},
      countries: [],
      regions: [],
      zones: [],
      cart: {
        items: [],
        totals: {
          subtotal: 0,
          discount: 0,
          shipping: 0,
          total: 0,
        },
      },
      order: {},
      success: "",
      errors: [],
    };
  },
  async mounted() {
    this.getCountries();
    this.getRegions();
    this.getZones();
    this.getCart();

    this.loading.page = false;
  },
  methods: {
    getCountries() {
      CheckoutService.getCountries().then((response) => {
        this.countries = response.data.countries;
      });
    },

    getRegions() {
      CheckoutService.getRegions(this.information.country_code).then(
        (response) => {
          this.regions = response.data.regions;
        }
      );
    },

    getZones() {
      if (this.information.region_code != "GH-AA") {
        this.information.zone_code = "";
      }

      CheckoutService.getDeliveryZones(this.information.region_code).then(
        (response) => {
          this.zones = response.data.delivery_zones.data;
        }
      );
    },

    getCart() {
      CartService.getCartItems().then((response) => {
        this.cart.items = response.data.cart_items.data;
        this.calculateTotals();
      });
    },

    validateCoupon() {
      this.loading.coupon = true;
      CheckoutService.validateCoupon({
        coupon_code: this.coupon_code,
      })
        .then((response) => {
          this.coupon = response.data.coupon;
          this.loading.coupon = false;
          this.coupon_code = "";
          this.calculateTotals();
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
          this.loading.coupon = false;
        });
    },

    submitOrder() {
      this.errors = [];
      this.loading.form = true;
      CheckoutService.submitOrder({
        coupon_code: this.coupon ? this.coupon.code : "",
        first_name: this.information.first_name,
        last_name: this.information.last_name,
        phone: this.information.phone,
        email: this.information.email,
        coupon_id: this.coupon ? this.coupon.id : "",
        delivery_method_code: this.deliveryMethod,
        shipping_country_code:
          this.deliveryMethod == "delivery"
            ? this.information.country_code
            : "",
        shipping_region_code:
          this.deliveryMethod == "delivery" ? this.information.region_code : "",
        shipping_delivery_zone_code:
          this.deliveryMethod == "delivery" ? this.information.zone_code : "",
        shipping_address:
          this.deliveryMethod == "delivery" ? this.information.address : "",
        shipping_additional_details:
          this.deliveryMethod == "delivery" ? this.information.notes : "",
        shipping_fee: this.cart.totals.shipping,
        subtotal: this.cart.totals.subtotal,
        discount: this.cart.totals.discount,
        total: this.cart.totals.total,
      })
        .then((response) => {
          this.order = response.data.order;

          // Initiate Paystack Payment.
          this.payWithPaystack();
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
          this.loading.form = false;
        });
    },

    payWithPaystack() {
      this.loading.order = true;
      // eslint-disable-next-line
      let handler = PaystackPop.setup({
        key: "pk_live_10b473520c6a614c13ddf408d3254b94d9da3353", // Replace with your public key
        email: this.order.id + "@thejeansshopgh.com",
        amount: 10,
        // amount: this.cart.totals.total * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit
        currency: "GHS", // Use GHS for Ghana Cedis or USD for US Dollars
        ref: this.order.id + "-" + Date.now(), // Replace with a reference you generated
        callback: function (response) {
          if (response.status === "success") {
            this.validateOrder();
          } else {
            this.errors = [
              "Transaction could not be completed. Please contact us if you believe this is an error.",
            ];
            this.loading.form = false;
            this.loading.order = false;
          }
        }.bind(this),

        onClose: function () {
          this.errors = [
            "Transaction could not be completed. Please contact us if you believe this is an error.",
          ];
          this.loading.form = false;
          this.loading.order = false;
        }.bind(this),
      });

      handler.openIframe();
    },

    validateOrder() {
      CheckoutService.validateOrderPayment({
        order_id: this.order.id,
      }).then((response) => {
        this.success = response.data.success;
        this.loading.form = false;
        this.loading.order = false;
      });
    },

    calculateTotals() {
      this.cart.totals.subtotal = 0;
      for (let index = 0; index < this.cart.items.length; index++) {
        const item = this.cart.items[index];
        this.cart.totals.subtotal =
          this.cart.totals.subtotal +
          item.quantity * item.product_variation.product.retail_price;
      }

      // Calculate shipping
      this.cart.totals.shipping = 0;
      if (this.deliveryMethod == "delivery") {
        // Get selected zone from list.
        for (let index = 0; index < this.zones.length; index++) {
          if (this.zones[index].code == this.information.zone_code) {
            var selectedZone = this.zones[index];
          }
        }
        this.cart.totals.shipping = selectedZone.cost;
      }

      // Calculate discounts
      if (this.coupon) {
        if (this.coupon.type == "percentage") {
          this.cart.totals.discount =
            (this.coupon.value / 100) * this.cart.totals.subtotal;
        } else if (this.coupon.type == "amount") {
          this.cart.totals.discount = this.coupon.value;
        }

        if (this.coupon.free_delivery == 1) {
          this.cart.totals.shipping = 0;
        }
      }

      // Calculate total
      this.cart.totals.total =
        this.cart.totals.subtotal * 1 +
        this.cart.totals.shipping * 1 -
        this.cart.totals.discount;
    },

    formatAmount(number) {
      return new Intl.NumberFormat("en-GH", {}).format(number);
    },
  },
};
</script>