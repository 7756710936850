<template>
  <div>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container">
        <div class="container-fluid">
          <div class="mobile-menu">
            <button
              class="navbar-toggler text-start fw-bolder btn-lg"
              style="
                font-size: 2.2rem;
                color: black;
                margin-top: 5px;
                outline: 0;
              "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i class="bi bi-justify-left"></i>
            </button>
            <a class="navbar-brand show-mobile-only" href="/">
              <img
                class="mt-2 mb-2"
                src="@/assets/media/images/logo/logo-mobile.png"
                alt="The Jeans Shop Logo - Horizontal - On White"
                width="auto"
                height="40"
              />
            </a>
            <a
              class="show-mobile-only"
              href="/cart"
              style="
                font-size: 2rem;
                text-decoration: none;
                color: black;
                margin-top: 10px;
              "
            >
              <i class="bi bi-cart"></i>
              <span
                v-if="cartCount > 0"
                class="badge badge-dark fw-bolder"
                style="
                  background-color: black;
                  position: absolute;
                  margin-left: -10px;
                  margin-top: 0px;
                  font-size: 0.7rem;
                  border-radius: 15px;
                "
                >{{ cartCount }}</span
              >
            </a>
          </div>

          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav d-flex justify-content-around w-100">
              <!-- <li class="nav-item my-auto show-desktop-only">
                <i class="bi-search fs-6"></i>
              </li> -->
              <li class="nav-item my-auto">
                <a class="nav-link active" aria-current="page" href="/">HOME</a>
              </li>
              <li class="nav-item my-auto">
                <a class="nav-link" href="/collections">OUR COLLECTIONS</a>
              </li>
              <li class="nav-item my-auto">
                <a class="nav-link" href="/shop">OUR SHOP</a>
              </li>
              <li class="show-desktop-only">
                <a class="navbar-brand" href="/">
                  <img
                    class="mt-2 mb-2"
                    src="@/assets/media/images/logo/logo-horizontal-on-white.png"
                    alt="The Jeans Shop Logo - Horizontal - On White"
                    width="auto"
                    height="50"
                  />
                </a>
              </li>
              <li class="nav-item my-auto">
                <a class="nav-link" href="/cart">
                  CART
                  <span
                    v-if="cartCount > 0"
                    class="badge badge-dark fw-bolder"
                    style="
                      background-color: black;
                      position: absolute;
                      margin-left: 5px;
                      margin-top: 0px;
                      font-size: 0.7rem;
                      border-radius: 15px;
                    "
                    >{{ cartCount }}</span
                  ></a
                >
              </li>
              <li class="nav-item my-auto">
                <a class="nav-link" href="/wishlist">
                  WISHLIST
                  <span
                    v-if="wishlistCount > 0"
                    class="badge badge-dark fw-bolder"
                    style="
                      background-color: black;
                      position: absolute;
                      margin-left: 5px;
                      margin-top: 0px;
                      font-size: 0.7rem;
                      border-radius: 15px;
                    "
                    >{{ wishlistCount }}</span
                  >
                </a>
              </li>
              <li class="nav-item my-auto">
                <i class="bi bi-tiktok"></i> &nbsp;
                <span class="show-mobile-only-inline"> &nbsp;</span>
                <i class="bi bi-instagram"></i> &nbsp;
                <span class="show-mobile-only-inline"> &nbsp;</span>
                <i class="bi bi-pinterest"></i> &nbsp;
                <span class="show-mobile-only-inline"> &nbsp;</span>
                <i class="bi bi-facebook"></i> &nbsp;
                <span class="show-mobile-only-inline"> &nbsp;</span>
                <i class="bi bi-twitter"></i>
              </li>
              <!-- <li
                v-if="!$store.getters['user/object']"
                class="nav-item my-auto show-desktop-only"
              >
                <a class="nav-link" href="/login">
                  <i class="bi-person fs-5"></i>
                </a>
              </li> -->
              <li
                v-if="$store.getters['user/object']"
                class="nav-item dropdown my-auto show-desktop-only"
              >
                <span
                  class="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi-person fs-5"></i>
                </span>
                <ul class="dropdown-menu">
                  <!-- <li>
                    <a class="dropdown-item" href="#"
                      ><i class="bi-gift fs-6 me-2"></i>My Orders</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item" href="#"
                      ><i class="bi-recycle fs-6 me-2"></i>My Returns
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#"
                      ><i class="bi-ticket-perforated fs-6 me-2"></i>My Coupons
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#"
                      ><i class="bi-pin-map fs-6 me-2"></i>My Addresses
                    </a>
                  </li>
                  <li><hr class="dropdown-divider" /></li> -->
                  <li class="my-auto">
                    <a class="dropdown-item" href="/account-area/orders"
                      ><i class="bi-gift fs-6 me-2"></i>My Orders</a
                    >
                  </li>
                  <li class="my-auto">
                    <a class="dropdown-item" href="" @click.prevent="logout"
                      ><i class="bi-lock fs-6 text-danger me-2"></i>Logout</a
                    >
                  </li>
                </ul>
                <!-- <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Login</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li><hr class="dropdown-divider" /></li>
                  <li>
                    <a class="dropdown-item" href="#"
                      ><i class="bi-person fs-5"></i> Logout</a
                    >
                  </li>
                </ul> -->
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import router from '@/router';

export default {
  name: "MainHeader",
  props: ["cartCount", "wishlistCount"],
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
      router.push("/");
    },
  },
};
</script>

<style>
.navbar {
  position: relative;
}
.brand {
  position: absolute;
  left: 50%;
  margin-left: -50px !important; /* 50% of your logo width */
  display: block;
}

.nav-item {
  font-size: 0.8rem;
}
</style>