<template>
  <div>
    <loader v-if="loading.page"> </loader>
    <div v-else>
      <ul class="nav justify-content-center bg-dark">
        <li class="nav-item">
          <span class="nav-link fc-white"
            ><b>FREE DELIVERY</b> for orders <b>above GHS 5,000</b></span
          >
        </li>
      </ul>
      <div
        class="row horizontal-banner-full mobile-home-main-banner show-mobile-only"
      >
        <div class="pt-5 col-md-6 text-center">
          <div
            class="playfair-display h2 fc-white mb-2 mt-10"
            style="font-style: italic; font-size: 3rem"
          >
            New Arrivals
          </div>
        </div>
        <div class="col-md-6 mobile-about-first-paragraph">
          <div class="row justify-content-center">
            <div class="col-md-7">
              <a
                href="/shop"
                style="
                  text-decoration: none;
                  text-decoration: underline;
                  text-decoration-color: white;
                "
              >
                <h5
                  class="fc-white text-center fw-bolder"
                  style="text-transform: uppercase"
                >
                  Shop New Arrivals <i class="bi bi-arrow-right"></i>
                </h5>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="show-mobile-only text-center mt-4" style="font-size: 0.9rem">
        Transition into winter with our new finds.
      </div>
      <div
        class="row horizontal-banner-full model-lying-in-couch-banner mb-3 show-desktop-only"
      ></div>
      <div class="container show-desktop-only">
        <div class="row mt-5 justify-content-center">
          <div class="col-md-4">
            <h1 class="playfair-display" style="font-size: 2.5rem">
              New Arrivals
            </h1>
            <h6>Transition into winter with our new finds.</h6>
            <div
              v-if="categories"
              class="fw-bold mt-4 mb-4"
              style="font-size: 0.9rem"
            >
              <div
                v-for="category in categories"
                :key="category.id"
                class="row"
              >
                <div class="col-md-12">
                  <a
                  class="new-arrivals-category"
                    :href="`/shop?category=${category.id}`"
                    style="color: black"
                    >{{ category.name }}</a
                  >
                </div>
              </div>
            </div>
            <a href="/shop">
              <button type="button" class="btn btn-dark">
                <b style="font-weight: bolder">Browse Our Store Now</b>
              </button>
            </a>
          </div>
          <div class="col-md-8">
            <div class="row mb-5">
              <div
                class="col-md-4 p-3"
                v-for="product in products.new_arrivals"
                :key="product.id"
              >
                <product-grid-item v-on="$listeners" :product="product"></product-grid-item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div
          class="col-md-6 model-seated-in-trunk-banner show-mobile-only"
          style="height: 300px"
        ></div>
        <div class="col-md-6 p-5 fc-white bg-dark">
          <div class="row justify-content-center">
            <div class="col-md-7">
              <h1 class="playfair-display mt-5" style="font-size: 2.5rem">
                Introducing
              </h1>
              <h1 class="playfair-display" style="font-size: 2.5rem">
                Cargo Pants
              </h1>
              <div class="mb-3">
                The reviews about the particular cargo pants was absolutely
                crazy.
              </div>
              <div class="mb-3">
                Now is the time to get yours. Few pairs available.
              </div>
              <div class="mb-3">Size 6 - 18 UK</div>
              <a href="/shop?category=4">
                <button type="button" class="btn btn-light mb-5">
                  <b style="font-weight: bolder">SHOP NOW</b>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div
          class="col-md-6 model-seated-in-trunk-banner show-desktop-only"
        ></div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-md-12 text-center">
          <h5>NEW SEASON, NEW STYLES</h5>
          <h1 class="playfair-display" style="font-size: 2.5rem">Explore</h1>
          <div class="row mt-3 justify-content-center text-start">
            <div class="col-md-9">
              <div class="row">
                <div
                  class="col-lg-3 col-6 p-2"
                  v-for="product in products.explore"
                  :key="product.id"
                >
                  <product-grid-item v-on="$listeners" :product="product"></product-grid-item>
                </div>
              </div>
              <div class="text-center mt-3">
                <a href="/shop">
                  <button type="button" class="btn btn-dark">
                    <b style="font-weight: bolder">Browse Our Store Now</b>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row pt-5 horizontal-banner-full model-seated-on-staircase-banner justify-content-center show-mobile-only"
        style="text-align: center"
      >
        <div
          class="col-11 bg-dark p-5 pt-1 mx-auto fc-white"
          style="margin-top: 120px"
        >
          <div>
            <h1 class="playfair-display mt-5" style="font-size: 2.5rem">
              Affordable
            </h1>
            <div class="mb-3">
              We restock every 2 weeks. You are going to love our latest
              collections.
            </div>

            <div class="mb-3">
              Something unique yet stylish and comfortable. Grab a pair.
            </div>
            <div class="">Sizes ranging 6 - 16</div>
          </div>
        </div>
      </div>
      <div
        class="row pt-5 horizontal-banner-full model-seated-on-staircase-banner show-desktop-only"
      >
        <div class="col-md-1"></div>
        <div class="col-md-3">
          <div>
            <img
              class="mt-3"
              src="@/assets/media/images/logo/logo-compact-on-black.png"
              alt=""
              width="280px"
            />
          </div>
        </div>
        <div class="col-md-4"></div>
        <div class="col-md-3">
          <div
            class="row mt-auto bg-dark p-5 fc-white"
            style="min-height: 450px"
          >
            <h1 class="playfair-display mt-5" style="font-size: 2.5rem">
              Affordable
            </h1>
            <div class="mb-3">
              We restock every 2 weeks. You are going to love our latest
              collections.
            </div>

            <div class="mb-3">
              Something unique yet stylish and comfortable. Grab a pair.
            </div>
            <div class="mb-3">Sizes ranging 6 - 16</div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row bg-grey justify-content-center pt-5 show-desktop-only">
        <div class="col-md-6">
          <div class="row">
            <div
              class="col-md-6 model-seated-in-blue-faded-jacket-banner"
              style="min-height: 400px"
            ></div>
            <div class="col-md-6 bg-white p-5">
              <h1 class="playfair-display" style="font-size: 2.5rem">
                Unsure of your size?
              </h1>
              <div class="mb-3 mt-2">
                Discover your ideal size in less than 20 seconds
              </div>
              <div class="mb-3">Let us assist you.</div>
              <button type="button" class="btn btn-dark mb-5">
                <b style="font-weight: bolder">Check Now</b>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row show-desktop-only">
        <div class="col-md-6">
          <div class="row" style="margin-top: 200px">
            <div class="col-md-5"></div>
            <div class="col-md-7">
              <h1 class="playfair-display" style="font-size: 2.5rem">
                Crop Tops, Jackets <br />& T-Shirts Available
              </h1>
              <div class="mb-4 mt-4" style="font-size: 1.1rem">
                Explore our wide range of t-shirts and crop tops that you can
                use to style your jeans.
              </div>
              <div class="mb-4" style="font-size: 1.1rem">
                Prices range from 70 - 200 cedis.
              </div>
              <a href="/shop?category=6,7">
                <button type="button" class="btn btn-dark mb-5">
                  <b style="font-weight: bolder">Shop Now</b>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div
          class="col-md-6 models-in-pink-jacket-banner mb-10"
          style="min-height: 500px; margin-top: 100px"
        ></div>
      </div>
      <div class="row show-mobile-only">
        <div
          class="col-md-6 models-in-pink-jacket-banner"
          style="min-height: 500px"
        ></div>
        <div class="col-md-6 p-4">
          <div class="row">
            <div class="col-md-5"></div>
            <div class="col-md-7">
              <h1 class="playfair-display" style="font-size: 2.2rem">
                Crop Tops, Jackets <br />& T-Shirts Available
              </h1>
              <div class="mb-4 mt-4" style="font-size: 1.1rem">
                Explore our wide range of t-shirts and crop tops that you can
                use to style your jeans.
              </div>
              <div class="mb-4" style="font-size: 1.1rem">
                Prices range from 70 - 200 cedis.
              </div>
              <a href="/shop?category=6,7">
                <button type="button" class="btn btn-dark mb-5">
                  <b style="font-weight: bolder">Shop Now</b>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row bg-grey p-5">
        <div class="col-md-7 mt-2 mb-5 justify-content-center">
          <h1 class="playfair-display text-center" style="font-size: 2.5rem">
            FAQs
          </h1>
        </div>
        <div class="col-md-5"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import ShopService from "@/services/customer/ShopService";
import Loader from "@/components/Loader.vue";
import ProductGridItem from "@/components/shop/ProductGridItem.vue";

export default {
  name: "HomeView",
  components: {
    Loader,
    ProductGridItem,
  },
  data() {
    return {
      loading: {
        page: true,
      },
      categories: [],
      products: {
        explore: [],
        new_arrivals: [],
      },
    };
  },
  mounted() {
    this.$emit("refreshCartCount");
    this.$emit("refreshWishlistCount");
    
    ShopService.getCategories().then((response) => {
      this.categories = response.data.categories.data;
    });

    // Get Explore Products
    ShopService.getProducts(
      1,
      4,
      "",
      [],
      [],
      [],
      "products.views",
      "desc"
    ).then((response) => {
      this.products.explore = response.data.products.data;
    });

    // Get New Arrivals
    ShopService.getProducts(
      1,
      3,
      "",
      [],
      [],
      [],
      "products.created_at",
      "desc"
    ).then((response) => {
      this.products.new_arrivals = response.data.products.data;
    });

    this.loading.page = false;
  },
};
</script>
