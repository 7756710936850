<template>
  <div class="row" v-if="cartItem != null">
    <div class="col-3" style="padding-left: 0">
      <img
        :src="cartItem.product_variation.product.images[0].file.storage_link"
        width="100%"
        class="lazyload mobile-cart-image"
      />
    </div>
    <div class="col-9 p-4 pe-2 pt-0 mobile-cart-details">
      <div class="row mt-3">
        <div class="col-md-12 p-0 spread-cols-to-ends">
          <div>
            <span class="fw-bolder"
              >{{ cartItem.product_variation.product.name }}
            </span>
            <div>
              <span style="display: flex; gap: 10px">
                <div
                  v-if="cartItem.product_variation.colour_code"
                  class="box-shadow-light"
                  :style="`width: 30px; height: 18px; background-color: #${cartItem.product_variation.colour_code}; margin-top: 5px`"
                ></div>
                <span class="fw-normal mt-1" style="font-size: 0.8rem"
                  >{{ cartItem.product_variation.size.name }}
                </span>
              </span>
            </div>
            <div class="input-group mt-3" style="width: 60%">
              <button
                @click="updateCartItem('delete')"
                style="border: 0.05rem solid #dee2e6 !important"
                class="btn btn-outline-dark fw-bolder"
                type="button"
                id="button-addon1"
              >
                -
              </button>
              <input
                v-model="cartItem.quantity"
                type="text"
                class="form-control text-center fw-bolder"
                placeholder=""
              />
              <button
                @click="updateCartItem('add')"
                style="border: 0.05rem solid #dee2e6 !important"
                class="btn btn-outline-dark fw-bolder"
                type="button"
                id="button-addon1"
              >
                +
              </button>
            </div>
          </div>
          <div class="">
            <div class="fw-bold text-end">
              <span class="currency_code">GH₵ </span
              >{{
                cartItem.product_variation.product.retail_price *
                cartItem.quantity
              }}
            </div>

            <div class="fw-bold text-end mt-2" style="font-size: 1.2rem">
              <a
                class="pr-2"
                :href="`/shop/product/${cartItem.product_variation.product.id}/${cartItem.product_variation.product.slug}`"
              >
                <i class="bi bi-search text-primary"></i>
              </a>
              <span class="fw-light"> | </span>
              <a
                href="/"
                @click.prevent="$emit('remove')"
              >
                <i class="bi bi-trash text-danger"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartService from "@/services/customer/CartService";
export default {
  name: "CartItem",
  props: ["item"],
  mounted() {
    this.cartItem = this.item;
  },
  data() {
    return {
      cartItem: null,
    };
  },
  methods: {
    updateCartItem(action) {
      if (action == "add") {
        this.cartItem.quantity++;
      } else {
        this.cartItem.quantity--;
      }
      CartService.updateCartItem(this.cartItem.id, {
        quantity: this.cartItem.quantity,
        product_variation_id: this.cartItem.product_variation_id,
      }).then((response) => {
        this.cartItem = response.data.cart_item;
      });
    },
  },
};
</script>