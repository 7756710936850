<template>
  <div class="mb-5">
    <loader v-if="loading.page"></loader>
    <div v-else-if="wishlist_items.length == 0">
      <div class="row mt-10 text-center">
        <i class="bi bi-heart mb-3 h1"></i>
        <h1
          class="mb-3"
          style="
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 900;
            letter-spacing: 0.1rem;
          "
        >
          Your Wishlist Is Empty
        </h1>
        <div>
          <a href="/shop">
            <button type="button" class="btn btn-dark">
              <b style="font-weight: bolder; text-transform: uppercase"
                >Browse Our Store Now</b
              >
            </button>
          </a>
        </div>
      </div>
    </div>
    <div v-else class="row mt-5 justify-content-center">
      <div class="col-md-5">
        <div class="row">
          <div class="col-md-12">
            <div class="justify-content-center text-center">
              <h1
                class="playfair-display"
                style="font-size: 2rem; font-style: italic"
              >
                Your Wishlist
              </h1>
              <div class="row mt-4">
                <div v-if="success" class="alert alert-success" role="alert">
                  {{ success }}
                </div>
                <div
                  v-if="errors.length > 0"
                  class="alert alert-danger"
                  role="alert"
                >
                  <ul>
                    <li v-for="error in errors" :key="error">{{ error }}</li>
                  </ul>
                </div>
                <div
                  class="col-md-12 mb-1 text-start"
                  v-for="item in wishlist_items"
                  :key="item.id"
                >
                  <div class="row">
                    <div class="col-3">
                      <img
                        :src="item.product.images[0].file.storage_link"
                        width="100%"
                        class="lazyload"
                      />
                    </div>
                    <div
                      class="col-9 p-4 pt-2 mobile-wishlist-detail-area-main"
                    >
                      <div class="row mt-1 mobile-wishlist-detail-area">
                        <div class="col-md-12 p-0 spread-cols-to-ends">
                          <div>
                            <span class="fw-bolder">{{
                              item.product.name
                            }}</span>
                            <div style="font-size: 0.7rem" class="mt-1">
                              Sizes:
                              <div>
                                <span
                                  class="fw-bold"
                                  v-for="(size, index) in item.product.sizes"
                                  :key="size.id"
                                  >{{ size.name
                                  }}<span
                                    v-if="
                                      index != item.product.sizes.length - 1
                                    "
                                    >,
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div
                              v-if="item.product.colours.length > 0"
                              style="font-size: 0.7rem"
                              class="mt-1"
                            >
                              Colours:
                              <div style="display: flex" class="mt-1">
                                <div
                                  v-for="colour in item.product.colours"
                                  :key="colour.id"
                                >
                                  <div
                                    class="colour-sample-palette box-shadow-light"
                                    :style="`background-color: #${colour.code}; margin-right: 5px`"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="fw-bold">
                            <span class="currency_code">GH₵ </span
                            >{{ item.product.retail_price }}
                          </div>
                        </div>
                        <div class="col-md-12 mt-3 p-0 spread-cols-to-ends">
                          <div class="fw-bold">
                            <a
                              :href="`/shop/product/${item.product.id}/${item.product.slug}`"
                            >
                              <button type="button" class="btn btn-dark">
                                <b style="font-weight: bolder">View Product</b>
                              </button>
                            </a>
                          </div>
                          <div class="fw-bold">
                            <a
                              href="/"
                              @click.prevent="
                                removeFromWishlist(item.product.id)
                              "
                            >
                              <button
                                type="button"
                                class="btn btn-outline-danger"
                              >
                                <b>Remove</b>
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="mt-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import Loader from "@/components/Loader.vue";
import WishlistService from "@/services/customer/WishlistService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "WishlistView",
  components: {
    Loader,
  },
  data() {
    return {
      wishlist_items: [],
      loading: {
        page: true,
      },
      success: "",
      errors: [],
    };
  },
  mounted() {
    WishlistService.getWishlistItems().then((response) => {
      this.wishlist_items = response.data.wishlist_items.data;
      this.loading.page = false;
    });
  },

  methods: {
    removeFromWishlist(productId) {
      WishlistService.deleteWishlistItem(productId)
        .then((response) => {
          this.success = response.data.message;
          WishlistService.getWishlistItems().then((response) => {
            this.wishlist_items = response.data.wishlist_items.data;
            this.loading.page = false;
          });
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },
  },
};
</script>